// ハンバーガーメニュー
const buttonHamburger = document.querySelector('#js-buttonHamburger') as HTMLElement;

buttonHamburger.onclick = () => {
  const header = document.getElementsByClassName('l-header')[0];
  const pos = document.documentElement.scrollTop || document.body.scrollTop;
  if (header.classList.contains('is-fixed')) {
    header.classList.add('is-bg');
  }
  if (header.classList.contains('is-open')) {
    header.classList.remove('is-bg');
  }
  const buttonHamburgerMenu = document.querySelector('#js-buttonHamburgerMenu') as HTMLElement;
  buttonHamburgerMenu.classList.toggle('is-open');
  header.classList.toggle('is-open');

  if (buttonHamburger.getAttribute('aria-expanded') == 'false') {
    buttonHamburger.setAttribute('aria-expanded', 'true');

    document.body.classList.add('is-drawerActive');
    document.body.style.top = String(-pos);
  } else {
    const spFixed = document.querySelector('.l-header__spFixed') as HTMLElement;
    spFixed.classList.add('is-closing');
    window.setTimeout(function () {
      spFixed.classList.remove('is-closing');
    }, 300);

    buttonHamburger.setAttribute('aria-expanded', 'false');
    document.body.classList.remove('is-drawerActive');
    document.body.style.top = String(0);
    window.scrollTo(0, pos);
  }
};
